<template>
  <div>
    <div class="main">
      <div class="tip">热租</div>
      <div class="content">
        <div class="contentPic">
          <img src="../../assets/img/main/card-tip.png" alt="" />
        </div>
        <div class="contentRrmark">
          <div class="left">中储发展(沈阳)物流有限公司</div>

          <div class="right">
            <div class="img"></div>
            <p>河北</p>
          </div>
        </div>
      </div>
      <div class="bottom">{{ bottomText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    bottomText: {
      type: String,
      default: "已上线货兑宝",
    },
  },
  data() {
    return {}
  },

  mounted() {},
  methods: {},
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .main {
    width: 357px;
    height: 257px;
    background-color: white;
    // border: 1px solid #333333;
    // margin: 100px;
    overflow: hidden;
    > .tip {
      width: 49px;
      height: 56px;
      position: relative;
      left: 11px;
      z-index: 100;
      background-image: url("../../assets/img/main/card-tip.png");
      background-size: 100% 100%;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    > .bottom {
      width: 359px;
      height: 39px;
      position: relative;
      top: -56px;
      background-image: url("../../assets/img/main/矩形 18 拷贝 4@2x.png");
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fefefe;
      text-align: center;
      line-height: 39px;
    }
    > .content {
      width: 100%;
      height: 217px;
      position: relative;
      top: -56px;
      // display: flex;
      // flex-direction: column;
      background-color: antiquewhite;
      > .contentPic {
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 88%;
        > img {
          width: 64px;
          height: 64px;
        }
      }
      > .contentRrmark {
        z-index: 100;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 13px 0 22px;
        box-sizing: border-box;
        text-align: left;
        > .left {
          flex-grow: 0.7;
          // width: 203px;
          font-size: 16px;
          line-height: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #fefefe;
        }
        > .right {
          width: 50px;
          display: flex;
          flex-direction: row;
          p {
            line-height: 16px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fefefe;
          }
          > .img {
            width: 13px;
            height: 16px;
            background-image: url("../../assets/img/main/location-icon.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .main {
    width: 332px;
    height: 391px;
    background: #ffffff;
    // border: 1px solid #333333;
    margin: 100px;
    overflow: hidden;
    > .tip {
      width: 46px;
      height: 53px;
      position: relative;
      left: 11px;
      z-index: 100;
      background-image: url("../../assets/img/main/card-tip.png");
      background-size: 100% 100%;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    > .bottom {
      width: 334px;
      height: 56px;
      position: relative;
      top: -56px;
      background-image: url("../../assets/img/main/矩形 18 拷贝 4@2x.png");
      background-size: 100% 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #fefefe;
      line-height: 56px;
      text-align: center;
    }
    > .content {
      width: 100%;
      height: 335px;
      position: relative;
      top: -56px;
      // display: flex;
      // flex-direction: column;
      > .contentPic {
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: antiquewhite;
        height: 68%;
        > img {
          width: 64px;
          height: 64px;
        }
      }
      > .contentRrmark {
        // padding: 10px 0;
        z-index: 100;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // padding: 0 13px 0 22px;
        > .left {
          flex-grow: 0.7;
          // width: 203px;
          font-size: 22px;
          font-family: Microsoft YaHei;
          padding: 16px 18px;
          font-weight: bold;
          color: #333333;
        }
        > .right {
          width: 200px;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          > .img {
            margin: 0 7px 0 19px;
            width: 14px;
            height: 18px;
            background-image: url("../../assets/mobile/detail/dingweixiao 拷贝@2x.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
