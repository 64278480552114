<template>
    <div class="main">


        <div style="width: 100vw;">
            <img style="width: 100%;" src="@/assets/rec.png" alt="">
        </div>

        <!-- 搜索 -->
        <div class="search_contariner">
            <div class="w12" style="padding: 60px 50px;">
                <!--  搜索器 -->
                <div style="display: flex;">
                    <div style="display: flex;align-items: center;margin-right: 15px;">
                        省：
                        <el-select @change='changeSheng' v-model="value1" placeholder="请选择">
                            <el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display: flex;align-items: center;">
                        市：
                        <el-select filterable @change='changeCity' v-model="value2" placeholder="请选择">
                            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width: 80px;
height: 40px;
background: #00B079;border-radius:4px;font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;text-align:center;line-height: 40px;margin-left:20px;cursor: pointer;" @click="chongzhi">
                        查询
                    </div>

                </div>

                <!-- 加载的数据 -->
                <div class="items" style="margin-top: 50px;display: flex;flex-wrap:wrap;" v-if="list.list.length > 0">
                    <div style="width:42%;padding: 30px 92px 0px 0px;" v-for="(item, index) in list.list" :key="index"
                        @click="goDetail(item.id)">

                        <div style="display: flex;justify-content:space-between;padding: 5px 0px;">
                            <div>
                                <span style="font-size: 18px;font-weight: bold;color: #333333;">{{ item.title }}</span>
                            </div>
                            <text class="mianyi" v-if="item.isFace == 1">面议</text>
                            <text class="wage" v-else>{{ item.salaryMin }}~{{ item.salaryMax }}k</text>
                            <div>
                                <span v-if="item.isFace == 1" style="font-size: 20px;
                                    font-family: PingFang SC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #F23838;
                                    "> 面议</span>
                                <span v-else style="font-size: 20px;
                                    font-family: PingFang SC-Medium, PingFang SC;
                                    font-weight: 500;
                                    color: #00B079;
                                    "> {{ item.salaryMin }}~{{ item.salaryMax }}k</span>
                            </div>
                        </div>

                        <div style="padding: 20px 0px;height: 100px;">
                            <p class="ellipsis_3" style="font-size: 16px;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;
                                line-height: 29px;">
                                {{ item.description }}
                            </p>
                        </div>


                        <div style="display: flex;justify-content: space-between;">
                            <span style="font-size: 14px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #CCCCCC;
                            line-height: 16px;">{{ item.companyName }}</span>
                            <span style="font-size: 14px;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #CCCCCC;
                            line-height: 16px;">{{ item.releaseTime }}发布</span>
                        </div>



                    </div>

                </div>
                <div v-else>
                    <el-empty :image-size="200"></el-empty>
                </div>

                <!-- 分页器 -->
                <div class="items" style="margin-top: 50px;display: flex;justify-content: center;" v-if="flag">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage4" :page-sizes="[10]" :page-size="10"
                        layout="total, sizes, prev, pager, next, jumper" :total="list.count">
                    </el-pagination>
                    <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage4" background layout="prev, pager, next" :total="list.count">
                    </el-pagination> -->
                </div>

            </div>


        </div>


    </div>
</template>
<script>
let id = 0;
import { banner } from "@/api/banner";
import house from "@/api/housedetail";
import mainpage from "@/api/mainpage";
import Card from "../components/Card/index.vue";
// import { EffectCube, Pagination } from "swiper";

export default {
    components: {
        Card,
    },
    data() {
        let that = this;
        return {
            prev: [{
                id: 1,
                value: '1',
                label: '河南省'
            }, {
                value: '选项2',
                label: '河北省'
            }],
            city: [{
                id: 1,
                value: '1',
                label: '郑州市'
            }, {
                value: '选项2',
                label: '周口市'
            }],
            value1: '',
            value2: '',

            //  搜索器

            currentPage4: 1,
            // modules: [EffectCube, Pagination],
            preActiveNum: 0,
            imgList: [
                require("../assets/logo/logo5.jpg"),
                require("../assets/logo/logo3.jpg"),
                // require("../assets/logo/logo4.jpg"),
                require("../assets/logo/logo1.jpg"),
                require("../assets/logo/logo2.jpg"),
                require("../assets/logo/logo6.jpg"),
                require("../assets/logo/logo7.jpg"),
                require("../assets/logo/logo8.jpg"),
                require("../assets/logo/logo9.jpg"),
            ],
            activeName: 'first',
            index: 0,
            selectedOptions: [],
            navInputProps: {
                lazy: true,
                value: "id",
                label: "name",
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    house
                        .mapList({
                            showAll: 0,
                            pid: node.level === 0 ? null : node.data.id,
                        })
                        .then((res) => {
                            res.data.forEach((it) => {
                                it.leaf = level >= 2;
                            });
                            resolve(res.data);
                        });

                    // setTimeout(() => {
                    //   const nodes = Array.from({ length: level + 1 }).map((item) => ({
                    //     value: ++id,
                    //     label: `选项${id}`,
                    //     leaf: level >= 2,
                    //   }));
                    //   // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //   resolve(nodes);
                    // }, 1000);
                },
            },
            swiperOption: {
                effect: "cube",
                cubeEffect: {
                    slideShadows: false,
                    shadow: false,
                },
                grabCursor: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,
                // mousewheel: true,
                // loop: true,
                speed: 2000,
                autoplay: {
                    delay: 6 * 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                },
                // 设置点击箭头
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // coverflowEffect: {
                //   rotate: 0, // 旋转的角度
                //   stretch: 100, // 拉伸   图片间左右的间距和密集度
                //   depth: 150, // 深度   切换图片间上下的间距和密集度
                //   modifier: 3, // 修正值 该值越大前面的效果越明显
                //   slideShadows: false, // 页面阴影效果
                // },
                on: {
                    init: function (swiper) {
                        that.isOver = true;
                    },
                    transitionStart: function () {
                        that.isOver = false;
                    },
                    transitionEnd: function () {
                        that.isOver = true;
                    },
                    // slideChangeTransitionStart() {
                    //   alert("111");
                    //   // setTimeout(() => {
                    //   that.isOver = false;
                    //   // }, 1000);
                    // },
                    // init() {
                    //   that.isOver = true;
                    //   // setTimeout(() => {
                    //   //   that.isOver = false;
                    //   // }, 3000);
                    // },

                    // slideChangeTransitionEnd() {
                    //   // alert("222");
                    //   // setTimeout(() => {
                    //   that.isOver = true;
                    //   // }, 2000);
                    // },
                },
            },
            isOver: false,
            swiperOptionVR: {
                slidesPerView: 3,
                slidesPerGroup: 6,
                // loop: true,
                // loopFillGroupWithBlank: true,
                spaceBetween: 60,
                slidesPerColumnFill: "row",
                slidesPerColumn: 2,

                paginationClickable: true,

                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                },
            },
            swiperData: [],
            form: {
                province: "",
                provinceId: "",
                provinceOp: [],
                city: "",
                cityId: "",
                countyId: "",
                cityOp: [],
            },

            searchData: "",
            showActive: false,
            newData: [],
            digital: [],
            activeName: "0",
            one: true,
            two: true,
            three: true,
            four: true,
            wareHouseListVr: {},
            wareHouseList: [],
            param: {
                type: 3,
                status: 2,
                upDown: 1,
                // uid: '',
                page: 1,
                viewType: 2,
                provinceId: '',
                cityId: ''
            },
            list: {
                list: []
            },
            addressList: [],
            cityList: [],
            flag: true
        };
    },
    watch: {
        activeName(cur, pre) {
            this.preActiveNum = pre;
        },
    },
    mounted() {
        this.get();
        // house.shareInfo().then((res) => {
        //   // console.log(0,res);
        // });
        // house
        //   .warehouseDetailById("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(111,res);
        //   });
        // house
        //   .warehouseRecommendList("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(222,res);
        // });
        // house.commonCategoryList().then((res) => {
        //   console.log(333, res);
        // });
        house.mapList({ showAll: 1, pid: null }).then((res) => {
            this.form.provinceOp = res.data;
            this.cascaderArry = res.data;
            // console.log(444,res);
        });
        // house
        //   .warehouseBannerList("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(555,res);
        //   });
        // house
        //   .warehousePageInfo({
        //     pageNum: 1,
        //     pageSize: 10,
        //     warehouseName: "",
        //     province: "",
        //     city: "",
        //     county: "",
        //     categoryIds: [],
        //   })
        //   .then((res) => {
        //     // console.log(666,res);
        //   });
        // house
        //   .mapAreaAndWarehouseInfos({
        //     warehouseName: "",
        //     province: "",
        //     city: "",
        //     county: "",
        //     categoryIds: [],
        //     areaIds: ["120000"],
        //   })
        //   .then((res) => {
        //     // console.log(777,res);
        //   });
        this.wareHouseListVr = {};
        mainpage.homeRecommendlist().then((res) => {
            // console.log(888, res);
            // this.wareHouseList = res.data

            console.log("res.data", res);

            const data = res.data.reverse();
            this.wareHouseList = data
            // this.wareHouseListVr = data.filter((item) => item.code == "vr")[0];
            // this.wareHouseList = data.filter((item) => item.code !== "vr");
            console.log(this.wareHouseList);

            // res.data.forEach((it) => {
            //   // console.log(it);
            //   if (it.code == "vr") {
            //     // this.wareHouseListVr = it;
            //   } else {
            //     this.wareHouseList.push(it);
            //   }
            // });
        });
        // house.mapAreaAndWarehouseInfos().then((res)=>{
        //   console.log(11,res);
        // })
    },
    created() {
        this.rentList()
        this.getAreaSheng()
    },
    methods: {
        chongzhi() {
            // this.value1 = ''
            // this.value2 = ''
            // this.param = {
            //     type: 3,
            //     status: 2,
            //     upDown: 1,
            //     page: 1,
            //     viewType: 2,
            //     provinceId: '',
            //     cityId: ''
            // }
            // this.cityList = ''
            this.param.page = 1
            this.flag = false
            setTimeout(() => {
                this.currentPage4 = 1
                this.flag = true
            }, 500)

            this.rentList()

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.param.page = val
            this.rentList()
        },
        async changeSheng(e) {
            if (e == 0) {
                this.param.provinceId = ''
                this.param.cityId = ''
                this.value2 = ''
                this.cityList = []
                this.rentList()
                return
            }
            this.value2 = ''
            this.param.provinceId = e
            this.cityList = await this.getArea(e)


        },
        changeCity(e) {
            console.log(e);
            this.param.cityId = e
            // this.rentList()
        },
        // 获取第一层省市区
        async getAreaSheng() {
            let data = await this.$api.base.getArea()


            this.addressList = data.list

            this.addressList.unshift({
                id: 0,
                lat: '',
                level: '',
                lng: '',
                name: '全部',
                pid: ''

            })
            console.log(this.addressList);


        },
        // 根据父级id获取市区
        async getArea(id) {
            let data = await this.$api.base.getArea({
                areaPid: id
            })

            return data.list


        },
        async rentList() {
            let {
                list: oldList,
                param
            } = this;
            let newList = await this.$api.base.rentList(param);
            console.log(newList);
            for (var i = 0; i < newList.list.length; i++) {
                newList.list[i].releaseTime = this.$util.formatTime(newList.list[i].releaseTime, "YY-M-D")
            }
            this.list = newList
            console.log(this.list);
            // if (newList.list == 0) {
            //     this.pageFlag = true
            //     // this.param.page--
            // }
            // if (this.param.page == 1) {
            //     this.list = newList
            // } else {
            //     newList.list = oldList.list.concat(newList.list)
            //     this.list = newList
            // }
        },
        goDetail(id) {
            this.$router.push({
                path: "/rentDetail",
                query: {
                    type: "rec",
                    id: id
                },
            });
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        get() {
            banner().then((res) => {
                this.swiperData = res.data;
                // console.log(res);
            });
        },

        openNewTabs(url) {
            window.open(url);
        },

        goToMap(val) {
            // console.log("cityw", this.selectedOptions);

            if (val == "more") {
                return this.$router.push({
                    path: "/map",
                });
            }

            this.$router.push({
                path: "/map",
                query: {
                    name: this.searchData,
                    provinceId: this.selectedOptions[0],
                    cityId: this.selectedOptions[1],
                    countyId: this.selectedOptions[2],
                },
            });
        },
        showHouses(it) {
            this.showActive = it === 1 ? true : false;
            if (it) {
                // console.log("housedetail", house);
            } else {
            }
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        mouseIn1(t) {
            this.one = false;
        },
        mouseIn2(t) {
            this.two = false;
        },
        mouseIn3(t) {
            this.three = false;
        },
        mouseIn4(t) {
            this.four = false;
        },
        mouseLeave1(t) {
            this.one = true;
        },
        mouseLeave2(t) {
            this.two = true;
        },
        mouseLeave3(t) {
            this.three = true;
        },
        mouseLeave4(t) {
            this.four = true;
        },
    },
};
</script>
<style scoped lang="less">
// /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
//     background-color: #00B079 !important;
//     color: #FFF;
// }
// /deep/ 
// .el-pagination.is-background .btn-prev {

//     background-color: #999999;

// }

// /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
//     color: #333333 !important;

// }
// /deep/ .el-pagination .btn-next, .el-pagination .btn-prev {
//     background: #999999 !important;
// }
/deep/ .el-pagination .btn-next {
    // width: 20px !important;
    background: center center no-repeat #00B079;

}

/deep/ .el-pagination .btn-prev {
    background: center center no-repeat #00B079;


}

.ellipsis_3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

/deep/ .el-dialog {

    border-radius: 10px !important;
}

/deep/ .el-pager li.active {
    color: #00B079;
    cursor: default;
}

/deep/ .el-pager li:hover {
    color: #00B079;
}

/deep/ .el-input.is-focus .el-input__inner {
    border-color: #00B079;
}

// /deep/ .el-pagination__sizes .el-input .el-input__inner:hover {
//     border-color: #00B079 !important;
// }
/deep/ .el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
    border-color: #00B079;
}

/deep/ .el-input.is-focus .el-input__inner {
    border-color: #00B079;
}

.listData {
    display: flex;
    flex-wrap: wrap;
}

.every_list {
    width: 45%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 20px;
    margin-bottom: 30px;
}

.every_list2 {
    width: 45%;
    margin-bottom: 60px;
    padding: 20px;
    margin-bottom: 30px;
}

.list_title {
    width: 80%;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.list_content {
    display: flex;
    flex-wrap: wrap;

}

.every_content {
    /* background-color: #999999; */
    width: 90%;
}

.everyContent {
    width: 45%;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 29px;
}

.everyContent1 {
    width: 80%;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 29px;
    margin-bottom: 20px;
}

.last_bottom {
    width: 80%;
    display: flex;
    justify-content: space-between;


}

.gongsi,
.shijian {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #CCCCCC;
}

.list_ico img {
    width: 24px;
    height: 24px;
}

img {
    position: relative;
    z-index: 0;
}

.el-o {
    font-weight: 500;
}

.firstActive {
    animation: firstActive 0.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes firstActive {
    0% {
        transform: translateX(-500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.secondActive {
    animation: secondActive 0.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes secondActive {
    0% {
        transform: translateX(500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.tAsBox {
    animation: fadenum 2s;
    display: block !important;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes fadenum {
    0% {
        opacity: 0;
        /* transform: translateY(70px); */
        top: 300px;
    }

    100% {
        opacity: 1;
        top: 250px;
    }
}

.w11 {
    padding: 50px 0 0px 0;
}

.slide-swiper {
    /* width: 357px !important; */
    height: 257px;
    margin-top: 60px;
    /* margin-left: 64px; */
}

.slide-swiper:first-child {
    margin-left: 0;
}

.slide-swiper .card {
    width: 357px !important;
    height: 257px !important;
}

/deep/.slide-swiper .card .card-box {
    width: 357px !important;
    height: 257px !important;
}

.vr-swiper .swiper-container {
    padding-bottom: 76px;
}

.flexws-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexws-box .warehouse-card {
    min-height: 476px;
}
</style>
