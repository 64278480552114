import request from "@/utils/request";
// params  query传参
// data    body传参
let mainpage
export default mainpage = {
  homeRecommendlist: function (warehouseId) {
    // code 判断是谁，vr 最新 或者
    return request({
      url: `/system/front/homeRecommendlist`,
      method: "get",
    });
  },

};